Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.webDashboardDepartmentsApiEndPoint = "account_block/dashboard_departments";
exports.webDashboardDeviceDetailsApiEndPoint = "account_block/fetch_dashboard_department_devises_detail";
exports.webSingleDeviceDetailsApiEndPoint = "account_block/show_dashboard_devise_detail";
exports.advanceSearchViewAllApiEndPoint = "/account_block/all_results";
exports.advanceSearchTopRecordsApiEndPoint = "/account_block/top_results"
// Customizable Area End
