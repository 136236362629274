import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {NavigateFunction} from "react-router-dom";
import { NavigationContext } from '../../../components/src/NavigationProvider';
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  searchValue?: string;
  searchList: string[];
  setSearchList: React.Dispatch<React.SetStateAction<string[]>>;
  searchCount?:number;
  selectedCountValue?:number;
  selectedValue?: string;
  isResultEmpty?: boolean;
  setIsResultEmpty : React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchPlaceHolder: React.Dispatch<React.SetStateAction<string>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  dashboardDepartments: any;
  selectedDepartment: string;
  singleDepartmentDeviceUser: any;
  SingleDeviceDataList : any;
  selectedDepartmentUser: string;
  expandedDevice: boolean;
  expandedARC: boolean;
  expandedNotification: boolean;
  expanded: any;
  currentId: number;
  isPreviousButton : boolean;
  isNextButton : boolean;
  currentIndex : number;
  searchValue: string;
  searchTopList: any;
  isSearchTab: boolean;
  currentPath: string;
  singleDepartmentName:string;
  currentInnerPage:number;
  currentPage:number;
  hasMoreData:boolean;
  loadingDepartment : boolean;
  loadingSingleDepartment : boolean;
  loadingSingleDevice : boolean;
  isDepartmentTab: boolean,
  isSingleDepartmentTab: boolean,
  isSingleDeviceTab: boolean
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  static contextType = NavigationContext;
  apiDashboardItemCallId: string = "";
  apiSingleDashboardItemCallId: any = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSingleDeviceCallId: any = "";
  apiTopFiveSearchId: any = "";
  apiViewAllCallId: any ="";
  apiAllSearchRecords: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      dashboardDepartments: [],
      selectedDepartment: "",
      singleDepartmentDeviceUser: [],
      SingleDeviceDataList: [],
      selectedDepartmentUser: "",
      expandedDevice: true,
      expandedARC: false,
      expandedNotification : false,
      expanded: [{
        expanded: false,
        key: "device"
      },{
        expanded: false,
        key: "ARC"
      },
      {
        expanded : false,
        key:"notification"
      }
    ],
     currentId: 0,
     isNextButton: false,
     isPreviousButton: false,
     currentIndex : 0,
     currentPage:0,
     currentInnerPage:0,
     singleDepartmentName:"",
     hasMoreData:true,
     searchValue : props.searchValue || "",
     searchTopList : [],
     isSearchTab: false,
     currentPath: `${window.location.pathname}${window.location.search}`,
     loadingDepartment:false,
     loadingSingleDepartment: false,
     loadingSingleDevice: false,
     isDepartmentTab: false,
     isSingleDepartmentTab: false,
     isSingleDeviceTab: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {    
    super.componentDidMount();
      this.getDashboardData(); 
    // Customizable Area Start
    this.setCorrectPage();
    window.addEventListener('popstate', this.handlePopState);
    // Customizable Area End
  }

  getDashboardData(isLoadMore = false): boolean {
    // Customizable Area Start
    const nextPage = isLoadMore ? this.state.currentPage  : 0;
    this.setState({
      selectedDepartment :"",
      selectedDepartmentUser : "",
      singleDepartmentDeviceUser : [],
      SingleDeviceDataList : [],
      isSearchTab:false,
    })
    this.setState({loadingDepartment : true});
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.webDashboardDepartmentsApiEndPoint}?page=${nextPage}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (webApiRequestCallId === this.apiDashboardItemCallId) {
        await this.handleDashboardItemResponse(webResponseJson);
      }
      else if (webApiRequestCallId === this.apiSingleDashboardItemCallId) {
        await this.handleSingleDashboardItemResponse(webResponseJson);
      }
      else if (webApiRequestCallId === this.apiSingleDeviceCallId) {
        await this.handleSingleDeviceResponse(webResponseJson);
      }
      else if (webApiRequestCallId === this.apiTopFiveSearchId) {
        await this.handleSearchList(webResponseJson)
      }
      else if (webApiRequestCallId === this.apiAllSearchRecords) {
        this.handleAllSeachRecord(webResponseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  navigationRouteFunction = (path : string) => {
    const navigate = this.context as NavigateFunction;
    if(typeof navigate == 'function'){
      navigate(path);
    }
  }
  setCorrectPage = (isLoadMore = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlDepartmentName = urlParams.get('department') || this.state.singleDepartmentName;
    const index = urlParams.get("index") || "";
    const urlSearch = urlParams.get('search') || "";
    const deviceId = urlParams.get("deviceId") || "";
    const deviceUser = urlParams.get("deviceUser") || "";
    this.setState({
      isDepartmentTab:false,
      isSingleDepartmentTab:false,
      isSingleDeviceTab:false,
    })
    if(urlSearch){
      this.setState({
        isDepartmentTab:false,
        isSingleDepartmentTab:true,
        isSingleDeviceTab:false,
        isSearchTab:true
      })
      this.handleApiSearchAllRecords(urlSearch);
    }
    else if(deviceId && deviceUser && String(index)){
      this.setState({
        isDepartmentTab:false,
        isSingleDepartmentTab:false,
        isSingleDeviceTab:true
      })
      this.handleApicallSingleDeviceUser(parseInt(deviceId),parseInt(index),deviceUser,urlDepartmentName);
      this.setSearchTab(urlDepartmentName)
    }
    else if(urlParams.get('department')){
      this.setState({
        isDepartmentTab:false,
        isSingleDepartmentTab:true,
        isSingleDeviceTab:false
      })
      this.handleSingleDepartment(urlDepartmentName,isLoadMore);
    }
    else{
      this.setState({
        isDepartmentTab: true,
        isSingleDepartmentTab:false,
        isSingleDeviceTab:false,
      })
        this.getDashboardData();
    }
  }
  setSearchTab = (department :string) => {
    const search = localStorage.getItem("searchQuery") || ""
    if(search && !department){
      this.setState({
        isSearchTab:true
      }
      )
    }
  }
  getDashboardDepartmentDataPage = () => {
    localStorage.setItem("currentPage","overview")
    this.props.setSearchPlaceHolder("Search");
    this.navigationRouteFunction("/Dashboard");
    this.setCorrectPage();
    this.setState({
      singleDepartmentDeviceUser: [],
      isSingleDepartmentTab:false,
      isDepartmentTab: true,
      hasMoreData: true,
      isSingleDeviceTab:false
    })
  }
  
  
  getStoreAllSearchResultDepartmentList = () => {
    const allSearchDepartmentList = JSON.parse(localStorage.getItem("allSearchDepartmentList")||"[]");
    const urlParams = new URLSearchParams(window.location.search);
    const urlSearch = urlParams.get('search') || "";
    const search = localStorage.getItem("searchQuery") || "";
    if(urlSearch === search && allSearchDepartmentList && allSearchDepartmentList.length>0){
      this.handleOpenDeviceUserData(allSearchDepartmentList)
      this.setState({
        singleDepartmentDeviceUser: allSearchDepartmentList,
        loading: false,
      })
    }
  }
  
  async componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }
  handlePopState = () => {
    this.setCorrectPage();
    const newPath = `${window.location.pathname}${window.location.search}`;
    if (newPath !== this.state.currentPath) {
      this.setState({ currentPath: newPath });
    }
  };
  removeFromStartToEnd(input :string) {
    const firstStart = input.indexOf("(");
    const firstEnd = input.indexOf(")", firstStart);
    const secondStart = input.indexOf("(", firstStart + 1);
    const secondEnd = input.indexOf(")", secondStart);
    if (secondStart !== -1 && secondEnd !== -1) {
        return input.slice(0, secondStart).trim() + input.slice(secondEnd + 1).trim();
    } else if (firstStart !== -1 && firstEnd !== -1) {
        return input.slice(0, firstStart).trim() + input.slice(firstEnd + 1).trim();
    } else {
        return input.trim();
    }
}
  handleSelectedSearchDepartMentClick = (inputString: string) => {
      const value = this.removeFromStartToEnd(inputString)
      this.handleSearchAllRecords(value);
      this.setState({
        isSearchTab:true
      })
  }
  handleAllSearch = () => {
    
    const searchQuery = localStorage.getItem("searchQuery") || "";
    this.navigationRouteFunction(`?search=${searchQuery}`);
    this.setCorrectPage();
    this.setState({
      selectedDepartmentUser:""
    })
  }
  componentDidUpdate(prevProps: Props,prevState : S) {
    if (this.props.searchValue && prevProps.searchValue !== this.props.searchValue) {
      const value = this.props.searchValue;
      this.handleAdvanceSearchTopRecords(value)
      this.setState({ searchValue: value });
      this.props.setIsResultEmpty(false);
      this.props.setLoading(true);
      this.props.setSearchValue("")
    }
    if(prevProps.searchCount !== this.props.searchCount){
      this.setState({isSearchTab:true,selectedDepartment:"",selectedDepartmentUser:""});
      const value = this.props.searchValue || "";
      this.handleSearchAllRecords(value);
      this.props.setSearchList([]);
    }
    if(prevProps.selectedValue !== this.props.selectedValue || prevProps.selectedCountValue !== this.props.selectedCountValue ){
      const value = this.props.selectedValue || "";
      this.handleSelectedSearchDepartMentClick(value);
      this.props.setSearchList([]);
    }
  }
  handleUpdateTheButtonStatus = (currentId: number) => {
    const departmentCount = this.state.singleDepartmentDeviceUser.length || parseInt(localStorage.getItem("selectedDepartmentTotalCountUser") || "0")
    this.setState({
      isNextButton: currentId < departmentCount,
      isPreviousButton: currentId > 0,
    });
  };
  
  handlePreviousClick = () => {

    const reduceOne = this.state.currentId - 1;
    const reduceOneIndex = this.state.currentIndex - 1;
    this.handleSingleDevice(reduceOneIndex,"")();
    this.setState({currentId : reduceOne});
    this.handleUpdateTheButtonStatus(reduceOneIndex);
  }
  handleNextClick = () => {
    const updateOne = this.state.currentId + 1;
    const updateOneIndex = this.state.currentIndex + 1;
    const departmentCount = this.state.singleDepartmentDeviceUser.length || parseInt(localStorage.getItem("selectedDepartmentTotalCountUser") || "0");
    if (updateOne > departmentCount) return;
    this.handleSingleDevice(updateOneIndex,"")();
    this.setState({ currentId: updateOne }, () => {
      this.handleUpdateTheButtonStatus(updateOne);
    });
  }
  getToken () {
    return localStorage.getItem('token')?.replace(/^"(.*)"$/, '$1') || "";
  }
  async handleDashboardItemResponse(webResponseJson :any) {
    if (webResponseJson && !webResponseJson.errors) {
      if (webResponseJson.departments.length === 1) {
        this.handleDepartmentClick(webResponseJson.departments[0].department);
      }

    if (webResponseJson.departments.length && webResponseJson.departments) {
        this.setState((prevState) => {
        const combinedData = prevState.currentPage === 0 
          ? webResponseJson.departments 
          : [...prevState.dashboardDepartments, ...webResponseJson.departments]; 

        const uniqueData = Array.from(new Map(combinedData.map((item: { department: string; }) => [item.department, item])).values());
      
        return {
          dashboardDepartments: uniqueData,
          currentPage: prevState.currentPage + 20,
          hasMoreData: webResponseJson.departments.length > 0, 
          loadingDepartment: false,
          errorMsg: "",
        };
      });
      
    } else {
      this.setState({ hasMoreData: false, loadingDepartment: false });
    }
    return;
    } 
    this.setState({
      errorMsg: webResponseJson.errors.token,
      loadingDepartment: false,
    });
  }
  
  async handleSingleDashboardItemResponse(webResponseJson :any) {  
    if (webResponseJson && !webResponseJson.errors) {
      if (webResponseJson.device_users.length && webResponseJson.device_users) {
        this.setState((prevState) => {
         const combinedData = prevState.currentInnerPage === 0 
            ? webResponseJson.device_users
            : [...prevState.singleDepartmentDeviceUser, ...webResponseJson.device_users]; 
          const uniqueData = Array.from(new Map(combinedData.map((item: { id__: number; }) => [item.id__, item])).values());
          return {
            singleDepartmentDeviceUser: uniqueData,
            currentInnerPage: prevState.currentInnerPage + 20,
            hasMoreData: webResponseJson.device_users.length > 0, 
            loadingSingleDepartment: false,
            errorMsg: "",
          };
        });
      } else {
        this.setState({ hasMoreData: false, loadingDepartment: false });
      }
      return;
    }
    this.setState({
      errorMsg: webResponseJson.errors.token,
      loadingSingleDepartment: false,
    });
  }
  
  async handleSingleDeviceResponse(webResponseJson :any) {
    if (webResponseJson && !webResponseJson.errors) {
      const response = this.removeNulls(webResponseJson)
      this.setState({
        SingleDeviceDataList: response.device_users,
        selectedDepartmentUser : response.device_users[0] ? response.device_users[0].device_id : this.state.selectedDepartmentUser,
        loading: false,
      });
      return;
    }
    this.setState({
      loading: false,
      errorMsg: webResponseJson.errors.token,
    });
  }
  async handleSearchList(webResponseJson : any) {
    if (webResponseJson && !webResponseJson.error) {
      const data = webResponseJson.dropdown_results || [];
      this.setState({searchTopList : webResponseJson.dropdown_results})
      this.props.setSearchList(webResponseJson.dropdown_results)
      if(data.length === 0){
        this.props.setIsResultEmpty(true);
      }
      this.props.setLoading(false)
      return;
    }else{
      this.setState({
        loading: false,
        errorMsg: webResponseJson.error,
      });
    }
    this.props.setIsResultEmpty(true);
    this.props.setLoading(false)    
  }
  handleOpenDeviceUserData = (resultData : any[]) => {
    const currentPage =localStorage.getItem("currentPage") || ""
    if(resultData.length === 1 && currentPage!=="overview") {
      localStorage.setItem("currentPage","search")
      this.navigationRouteFunction(`?deviceId=${resultData[0].id__}&deviceUser=${resultData[0].device}&index=1`)      
      this.setCorrectPage()
    }
  }
  handleAllSeachRecord(webResponseJson: any){
    if(webResponseJson && !webResponseJson.errors){
      this.setState({
        singleDepartmentDeviceUser: webResponseJson.all_results,
        singleDepartmentName: webResponseJson.all_results[0].department,
      })
      this.handleOpenDeviceUserData(webResponseJson.all_results)
    }
    this.setState({loadingSingleDepartment:false});
  }
  handleDepartmentClick = (department: string) => { 
    
    this.navigationRouteFunction(`?department=${department}`);
    this.setCorrectPage()
  }
  handleSingleDepartment = (department: string,isLoadMore = false) => {
    this.setState({
      selectedDepartment: department,
      loadingSingleDepartment:true,
      selectedDepartmentUser : "",
    })
    const nextPage = isLoadMore ? this.state.currentInnerPage : 0;
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSingleDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.webDashboardDeviceDetailsApiEndPoint+`?department=${department}&page=${nextPage}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  handleSingleDevice = (index: number,departmentName: string) => () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlDepartmentName = urlParams.get('department') || departmentName;
    if (!this.state.singleDepartmentDeviceUser || this.state.singleDepartmentDeviceUser.length === 0) {
        return;
    }
    const localSingleDepartmentDeviceUser = JSON.parse(JSON.stringify(this.state.singleDepartmentDeviceUser));
    if (index < 0 || index >= localSingleDepartmentDeviceUser.length) {
        return;
    }
    this.setState({singleDepartmentName : departmentName});
    const deviceId = localSingleDepartmentDeviceUser[index].id__;
    const deviceUser = localSingleDepartmentDeviceUser[index].device;
    this.navigationRouteFunction(`?department=${urlDepartmentName}&deviceId=${deviceId}&deviceUser=${deviceUser}&index=${index}`);
    this.setCorrectPage();
};

  handleApicallSingleDeviceUser = (id: number , index: number, selectedDepartmentUser: string,selectedDepartment:string) => {
    this.setState({
      selectedDepartmentUser,
      currentId : id,
      currentIndex : index,
      SingleDeviceDataList:[],
      loading:true,
      selectedDepartment,
      expandedDevice: true,
      expandedARC: false, 
      expandedNotification : false
    });
    this.handleUpdateTheButtonStatus(index);
    localStorage.setItem("deviceId",JSON.stringify(id));
    localStorage.setItem("deviceIndex",JSON.stringify(index));
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSingleDeviceCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.webSingleDeviceDetailsApiEndPoint+`?id=${selectedDepartmentUser}&department=${selectedDepartment}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  removeNulls = (data: any): any => {
    if (Array.isArray(data)) {
        return data
            .map(item => this.removeNulls(item)) 
            .map(item => item === null ? "" : item);
    } else if (typeof data === 'object' && data !== null) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const cleanedValue = this.removeNulls(value);
            acc[key] = cleanedValue === null ? "" : cleanedValue;
            return acc;
        }, {} as any);
    }
    return data;
  }
  handleAdvanceSearchTopRecords = (query: string) => {
    
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTopFiveSearchId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.advanceSearchTopRecordsApiEndPoint+`?query=${query}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  
    return true;
  }
  handleSearchAllRecords = (query: string) => {
    this.navigationRouteFunction(`?search=${query}`);
    this.setCorrectPage();
  }
  handleApiSearchAllRecords = (query: string) => {
    this.setState({
      singleDepartmentDeviceUser:[],
      selectedDepartmentUser:"",
      loadingSingleDepartment:true,
      selectedDepartment:""
    })
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStoreAllSearchResultDepartmentList();
    localStorage.setItem("searchQuery",query);
    this.apiAllSearchRecords = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.advanceSearchViewAllApiEndPoint+`?query=${query}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  
    return true;
  }

  // Customizable Area End
}
